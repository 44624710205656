* {
  margin: 0;
  padding: 0;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #ededed;
  transition: 500ms linear;
}
.dark-mode {
  background-color: #181a1af8;
  color: #b8b8b8;
  transition: 500ms linear;
}
.dark-mode a {
  color: #b8b8b8 !important;
  transition: 500ms linear;
}
.dark-mode .image img {
  filter: grayscale();
  transition: 500ms linear;
}
.dark-mode .LeftGroup img,
.dark-mode .WorkExperience img {
  filter: invert(100%);
  transition: 500ms linear;
}
h1 {
  font-size: 40px;
}
h2 {
  margin-bottom: 10px;
}
h3 {
  margin-top: 20px;
}
p {
  line-height: 30px;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}
a:visited {
  text-decoration: none;
}
a,
a:visited,
a:hover,
a:active {
  color: #000000;
  transition: 500ms linear;
}
.container {
  padding: 0 20px;
  margin: 0 auto 0 auto;
  max-width: 1200px;
}
.Header {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}
/* PERSONAL PROILE STARTS */
.PersonalProfile {
  display: flex;
  text-align: justify;
  padding: 20px 0;
  border-bottom: 1px solid #000000;
  flex-wrap: wrap;
}

.dark-mode .PersonalProfile {
  border-bottom: 1px solid #6b6b6b;
  transition: 500ms linear;
}
.PersonalProfile .image {
  flex: 25%;
  text-align: center;
}
.PersonalProfile .text {
  flex: 75%;
  line-height: 25px;
}
.PersonalProfile img {
  width: 235px;
  height: auto;
}
.PersonalProfile a {
  text-decoration: underline;
}
.PersonalProfile a:hover {
  color: rgb(238, 82, 35);
}
/* PERSONAL PROILE ENDS */

/* PAGE DIVISION STARTS */
.page {
  display: flex;
  flex-wrap: wrap;
}
.left {
  flex: 25%;
}
.right {
  flex: 75%;
  border-left: 1px solid #000000;
  /* border-top: 1px solid #000000; */
}

@media screen and (max-width: 897px) {
  .right {
    border: none;
  }
  .PersonalProfile {
    border: none;
  }
}
.dark-mode .right {
  border-left: 1px solid #6b6b6b;
  transition: 500ms linear;
}
/* PAGE DIVISION ENDS */

/* LEFT GROUP STARTS */
.LeftGroup {
  padding: 10px;
}
.LeftGroup h2 {
  text-align: center;
  margin-bottom: 15px;
}
.Box {
  display: flex;
  margin-bottom: 20px;
}
.Icon {
  flex: 20%;
}
.Text {
  flex: 80%;
}
.Text span {
  font-weight: 600;
}
.Icon img {
  width: 35px;
}
/* LEFT GROUP ENDS */

/* WORKEXPERIENCE STARTS */
.WorkExperience {
  text-align: justify;
  padding: 15px 25px;
  position: relative;
  flex-wrap: wrap;
}
.job {
  margin-bottom: 10px;
  position: relative;
}
.blackDot {
  position: absolute;
  background-color: #000000;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 18px;
  left: -11px;
}

.hollowDot {
  position: absolute;
  background-color: #f5f1f0;
  width: 15px;
  height: 15px;
  border: 1px solid #000000;
  border-radius: 50%;
  top: 5px;
  left: -34px;
}
/* WORKEXPERIENCE ENDS */

/* INTERESTS STARTS*/

.hobbyContainer {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.hobby {
  text-align: center;
  flex: 10%;
}
.WorkExperience img {
  width: 35px;
}
/* INTERESTS ENDS*/
/*SWITCH BUTTON STARTS*/
*,
*:after,
*:before {
  box-sizing: border-box;
}

input[type='checkbox'] {
  visibility: hidden;
}
input[type='checkbox']:checked + label {
  transform: rotate(360deg);
  background-color: #000;
}
input[type='checkbox']:checked + label:before {
  transform: translateX(27px);
  background-color: #fff;
}
label {
  display: flex;
  width: 70px;
  height: 30px;
  border: 4px solid;
  border-radius: 50em;
  position: relative;
  transition: transform 0.75s ease-in-out;
  transform-origin: 50% 50%;
  cursor: pointer;
}
label:before {
  transition: transform 0.75s ease;
  transition-delay: 0.5s;
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #000;
  border-radius: 50%;
  top: 1px;
  left: 8px;
}

/*SWITCH BUTTON ENDS*/
